<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-2">
        {{ $t(CREATE_PAGE_TITLE_PREFIX) }}
        —
        {{ getValueFromGivenObjectByKey(assetItem, 'product.sku', '') }}
        {{ getValueFromGivenObjectByKey(assetItem, 'product.name', '') }}
      </h3>

    </portal>
    <received-quote-in-house-form v-if="order.id && !isLoading" />
    <skeleton v-else />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import ReceivedQuoteInHouseForm from '../components/create/received-quote-in-house/Form.vue'
import Skeleton from '../components/create/request-service-quote/components/Skelton.vue'
// eslint-disable-next-line import/no-cycle
import mainConfig from '../config'
import config from '../components/create/request-service-quote/config'

export default {
  name: 'RequestServiceQuote',
  components: {
    Skeleton,
    ReceivedQuoteInHouseForm,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    serviceOrderInformationFormDefaultValue() {
      return this.$store.state[this.MODULE_NAME].serviceOrderInformationFormDefaultValue
    },
    assetItem() {
      return this.$store.state[this.MODULE_NAME].assetItem
    },
  },
  async created() {
    this.isLoading = true
    this.$store.commit(`${this.MODULE_NAME}/GET`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_ATTACHMENTS`, [])

    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(async ({ data }) => {
      const order = data

      const settings = await this.$store.dispatch(`${this.MODULE_NAME}/getCustomerSettings`)

      const { tax_rule } = settings.data.data

      this.$store.dispatch(`${this.MODULE_NAME}/getTestDamagedAsset`, { id: data.inventory_item_damaged_id })
        .then(({ data: { data } }) => {
          this.$store.commit(`${this.MODULE_NAME}/GET`, { ...order, customer: { customer_detail: { tax_rule } } })
          this.$store.commit(`${this.MODULE_NAME}/SET_ATTACHMENTS`, [...data.damaged?.attachments || [], ...order.attachments])
          this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_TYPE_ON_CHANGE`, this.SERVICE_TYPE_IN_HOUSE_SERVICE)
          this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_ORDER_INFORMATION_FORM`, {
            internal_order_reference_in_house: getValueFromGivenObjectByKey(order, 'internal_order_reference', ''),
          })
          this.$emit('setServiceType', this.SERVICE_TYPE_IN_HOUSE_SERVICE)
        })
        .finally(() => {
          this.isLoading = false
        })
    })
  },
  setup() {
    const {
      MODULE_NAME,
      quoteStatusBar,
      CREATE_PAGE_TITLE_PREFIX,
      CREATE_PAGE_TITLE_SUFFIX,
    } = mainConfig()

    const { SERVICE_TYPE_IN_HOUSE_SERVICE } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
      CREATE_PAGE_TITLE_PREFIX,
      CREATE_PAGE_TITLE_SUFFIX,
      getValueFromGivenObjectByKey,
      SERVICE_TYPE_IN_HOUSE_SERVICE,
    }
  },
}
</script>
