<template>
  <base-form-bottom path="home-orders-service-list">
    <div
      class="d-flex"
      style="gap: 8px"
    >
      <b-button
        v-for="({ variant, style, action, title, icon }, idx) in isOrderInInvoice ? formBottomActionsInvoice : formBottomActions"
        :key="idx"
        :variant="variant"
        class="p-0 d-flex justify-content-center align-items-center"
        :style="style"
        :disabled="loadingFor.isLoading || disableByStateAndStatus(action)"
        @click="submit(action)"
      >
        <b-spinner
          v-if="loadingFor.isLoading && loadingFor.action === action"
          small
          variant="secondary"
        />
        <span
          v-else
          class="d-flex align-items-center"
          style="gap: 8px"
        >
          <feather-icon
            v-if="icon"
            :icon="icon"
          />
          <span>
            {{ $t(title) }}
          </span>
        </span>
      </b-button>
    </div>
  </base-form-bottom>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import { mapGetters } from 'vuex'
import jsonToFormData from '@/libs/jsonToFormData'
import BaseFormBottom from '@/views/main/orders/components/use-as-global/BaseFormBottom.vue'
import config from './config'
import mainConfig from '../../../config'
import receivedQuoteConfig from '../received-quote/config'

export default {
  name: 'FormBottom',
  components: {
    BSpinner,
    BButton,
    BaseFormBottom,
  },
  props: {
    formRefs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingFor: {
        isLoading: false,
        action: null,
      },
    }
  },
  computed: {
    ...mapGetters('service', ['getTotalDataOfForms']),
    isOrderInInvoice() {
      return this.getTotalDataOfForms.order.status === 1 && this.getTotalDataOfForms.order.state === 2
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    disableByStateAndStatus(action) {
      return action === this.FORM_BUTTON_VOID.action || action === this.FORM_BUTTON_SELL_ASSET.action
    },
    redirectTo(id, action) {
      // if (action === this.FORM_BUTTON_ACCEPT.action) {
      //   this.$router.push({ name: 'invoice', params: { id } })
      // } else {
      // }
      this.$router.push({ name: 'home-orders-service-list' })
    },
    submit(action) {
      const { formRefs: { requestServiceQuoteForm }, getTotalDataOfForms } = this
      const {
        servicesPerformed, otherCharges, internal_order_reference_in_house, order: { id, state, status }, attachments,
      } = getTotalDataOfForms

      requestServiceQuoteForm.validate().then(success => {
        if (success) {
          this.loadingFor = {
            isLoading: true,
            action,
          }
          const convertedMappedDataToFormData = jsonToFormData({
            id,
            attachments: attachments.filter(attachment => !attachment.id),
            internal_order_reference: internal_order_reference_in_house,
            other_charges: otherCharges.map(({
              name, unit, price, discount, tax,
            }) => ({
              name, unit, subtotal: ((price - discount) + ((price - discount) * tax / 100)).toFixed(2), discount, price, tax,
            })),
            service_performed: servicesPerformed.map(({
              name, unit, price, discount, tax,
            }) => ({
              name, unit, subtotal: ((price - discount) + ((price - discount) * tax / 100)).toFixed(2), discount, price, tax,
            })),
          })

          const url = state === 2 && status === 1 ? 'invoice-service' : 'close-service-in-house'

          this.sendNotification(this, convertedMappedDataToFormData, `${this.MODULE_NAME}/${url}`)
            .then(({ data }) => {
              this.redirectTo(id, action)
              this.$emit('clear')
            })
            .catch(err => {
              this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
            }).finally(() => {
              this.loadingFor = {
                isLoading: false,
                action: null,
              }
            })
        } else {
          scrollToError(this, requestServiceQuoteForm)
        }
      })
    },
  },
  setup() {
    const {
      FORM_BUTTON_VOID,
      FORM_BUTTON_PRINT,
      formBottomActions,
      FORM_BUTTON_SELL_ASSET,
      FORM_BUTTON_BILL_SERVICE,
    } = config()

    const {
      STATE_SO,
      STATE_RFQ,
      STATUS_VOID,
      MODULE_NAME,
      STATUS_READY,
      STATE_INVOICE,
      STATUS_WARNING,
      STATUS_NO_ACTION,
      STATUS_ATTENTION,
      STATUS_IN_PROGRESS,
    } = mainConfig()

    const { formBottomActionsInvoice } = receivedQuoteConfig()

    return {
      STATE_SO,
      STATE_RFQ,
      STATUS_VOID,
      MODULE_NAME,
      STATUS_READY,
      STATE_INVOICE,
      STATUS_WARNING,
      FORM_BUTTON_VOID,
      STATUS_NO_ACTION,
      STATUS_ATTENTION,
      FORM_BUTTON_PRINT,
      formBottomActions,
      formBottomActionsInvoice,
      STATUS_IN_PROGRESS,
      FORM_BUTTON_SELL_ASSET,
      FORM_BUTTON_BILL_SERVICE,
    }
  },
}
</script>
