<template>
  <div>
    <div>
      <error-alert
        :fields="{}"
        :error="{}"
      />
      <!--   Form Body   -->
      <b-form>
        <validation-observer
          ref="requestServiceQuoteForm"
        >
          <asset class="mb-1" />
          <div class="mb-1">
            <dynamic-table
              :title="SERVICES_PERFORMED_TABLE_TITLE"
              list-name="servicesPerformed"
              class="mb-1"
            />
            <dynamic-table
              :title="OTHER_CHARGES_ITEMS_TABLE_TITLE"
              list-name="otherCharges"
            />
          </div>
          <div
            class="bg-white p-1 mb-1"
            style="border-radius: 5px"
          >
            <upload-attachment
              :title="RECEIVED_ATTACHMENTS"
              class="service-attachment"
            />
            <component
              :is="serviceOrderInformationFields[field].type"
              v-for="field in ['internal_order_reference_in_house']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="serviceOrderInformationForm[field]"
              class="h-auto"
              v-bind="getProps(field, serviceOrderInformationFields)"
            />
          </div>
        </validation-observer>
      </b-form>
    </div>
    <form-bottom :form-refs="$refs" />
    <!--    <b-s-warning-modal-->
    <!--      ref="confirm-modal"-->
    <!--      @onNextTick="onNextTick"-->
    <!--    />-->
  </div>
</template>

<script>
// Components
import { BForm } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import DynamicTable from '@/views/main/orders/view/service/components/common/DynamicTable/DynamicTable.vue'
import { getProps } from '@core/utils/utils'
import config from './config'
import generalConfig from '../../../config'
import FormBottom from './FormBottom.vue'
import Asset from '../../common/Asset.vue'
import UploadAttachment from '../../common/UploadAttachment.vue'

export default {
  name: 'Form',
  components: {
    Asset,
    BForm,
    ErrorAlert,
    FormBottom,
    DynamicTable,
    UploadAttachment,
    ValidationObserver,
  },
  data() {
    return {
      onSubmit: false,
    }
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order ?? {}
    },
    serviceTypeValue() {
      return this.$store.state[this.MODULE_NAME].serviceType
    },
    isOrderTypeSendOutForServices() {
      return this.SERVICE_TYPE_SEND_OUT_SERVICE_RESPONSE_ITEM_TYPE === this.order.service_type
    },
    isOrderInInvoice() {
      return this.order.status === 1 && this.order.state === 2
    },
    serviceOrderInformationForm() {
      return this.$store.state[this.MODULE_NAME].serviceOrderInformationForm
    },
  },
  methods: {
    getProps,
    onServiceTypeChange(type) {
      this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_TYPE_ON_CHANGE`, type)
    },
  },
  setup() {
    const {
      RECEIVED_ATTACHMENTS,
      serviceOrderInformationFields,
      SERVICES_PERFORMED_TABLE_TITLE,
      OTHER_CHARGES_ITEMS_TABLE_TITLE,
    } = config()

    const { quoteStatusBar, MODULE_NAME } = generalConfig()

    return {
      MODULE_NAME,
      quoteStatusBar,
      RECEIVED_ATTACHMENTS,
      serviceOrderInformationFields,
      SERVICES_PERFORMED_TABLE_TITLE,
      OTHER_CHARGES_ITEMS_TABLE_TITLE,
    }
  },
}
</script>
<style lang="scss">
  .service-attachment .attachments-row {
    flex-direction: row !important;
  }
</style>
