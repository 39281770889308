import mainStore from '@/store'
import serviceModule from '@/store/main/orders/service'

import {
  L_TAGS_INPUT,
  SELECT_CHILD_INPUT, SELECT_INPUT, TEXT_INPUT, TEXTAREA_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  // Constants
  const MODULE_NAME = 'service'
  const MODULE_NAME_SET_ORDERS = 'set-order-service-orders'
  const MODULE_NAME_SET_ORDERS_CLONE = 'cloneData'
  const MODULE_NAME_ATTACHMENT = 'attachment'
  const MODULE_NAME_CLONE = 'cloneData'
  const MODULE_NAME_ORDERS = 'orders'

  const store = mainStore
  const model = serviceModule

  const serviceType = [
    {
      value: 'send-out',
      title: 'Send out for Services',
    },
    {
      value: 'in-house',
      title: 'In-house Service',
    },
  ]

  const SERVICE_TYPE_SEND_OUT_SERVICE = 'send-out'
  const SERVICE_TYPE_SEND_OUT_SERVICE_RESPONSE_ITEM_TYPE = 'send_out_for_services'
  const SERVICE_TYPE_IN_HOUSE_RESPONSE_ITEM_TYPE = 'in_house_service'
  const SERVICE_TYPE_IN_HOUSE_SERVICE = 'in-house'

  const tableColumns = [
    {
      key: 'order_no',
      label: 'Order No.',
    },
    {
      key: 'state',
      label: 'State',
    },
    {
      key: 'order_date',
      label: 'Order Date',
    },
    {
      key: 'to',
      label: 'To',
    },
    {
      key: 'ship_by',
      label: 'Ship By',
    },
    {
      key: 'return_by',
      label: 'Return by',
    },
  ]

  // Asset === === // ==>
  // Service Order Information === === =>
  const SERVICE_ORDER_INFORMATION_TITLE = 'Service Order Information'

  const autoSuggestTableColumnsAccount = [
    {
      key: 'cust_name_dba',
      label: '',
    },
  ]
  const uploadAttachmentTableCol = [
    {
      label: 'File Name',
      thStyle: {
        width: '25%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Date',
      thStyle: {
        width: '15%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Type',
      thStyle: {
        width: '10%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Category',
      thStyle: {
        width: '20%',
        fontWeight: 'bold',
      },
    },
    {
      label: '',
      thStyle: {
        width: '21%',
        fontWeight: 'bold',
      },
    },
  ]

  const serviceOrderInformationFields = {
    customer_id: {},
    billing_contact_id: {
      type: SELECT_CHILD_INPUT,
      label: 'Billing Contact',
      store: 'billingContactOnPurchase',
      rules: 'required',
      firstSelect: true,
      options: {
        label: 'fullName',
      },
    },
    billing_email: {
      type: TEXT_INPUT,
      label: 'Billing Email',
      placeholder: '',
    },
    billing_phone: {
      type: TEXT_INPUT,
      label: 'Billing Phone',
      placeholder: '',
    },
    billing_address: {
      type: TEXTAREA_INPUT,
      label: 'Billing Address',
      placeholder: '',
    },
    internal_account_notes: {
      type: TEXTAREA_INPUT,
      label: 'Internal account notes (not visible to the supplier)',
      placeholder: '',
    },
    payment_term_id: {
      type: SELECT_INPUT,
      fParams: { is_active: 1 },
      fSearchParams: { is_active: 1 },
      label: 'Payment Terms',
      store: 'paymentTermList',
      rules: 'required',
      options: {
        label: 'label',
      },
    },
    internal_order_reference: {
      type: TEXTAREA_INPUT,
      label: 'Internal Order Reference (not visible to the supplier)',
      placeholder: 'Write notes here...',
    },
    internal_order_reference_in_house: {
      type: TEXTAREA_INPUT,
      label: 'Internal Order Reference',
      placeholder: 'Write notes here...',
      rules: 'required',
    },
    internal_notes: {
      type: TEXTAREA_INPUT,
      label: 'Order Notes (visible to the supplier)',
      placeholder: 'Write notes here...',
    },
    service_type: {},
  }
  // Service Order Information === // =>

  // Dispatch & Return === === =>
  const DISPATCH_FORM_TITLE = 'Dispatch'
  const RETURN_FORM_TITLE = 'Return'
  const DISPATCH_AND_RETURN_FORM_TITLE = 'Dispatch and Return'
  const dispatchAndReturnFields = {
    dispatch_warehouse_id: {
      type: SELECT_INPUT,
      label: 'Dispatch Warehouse',
      store: 'warehouseList',
      rules: 'required',
      firstSelect: true,
      options: {
        label: 'label',
      },
    },
    dispatch_method_id: {
      type: SELECT_INPUT,
      label: 'Dispatch Method',
      store: 'dispatchAndReturnMethodsList',
      rules: 'required',
      firstSelect: true,
      options: {
        label: 'name',
      },
    },
    return_method_id: {
      type: SELECT_INPUT,
      label: 'Return Method',
      store: 'dispatchAndReturnMethodsList',
      rules: 'required',
      firstSelect: true,
      options: {
        label: 'name',
      },
    },
    return_warehouse_id: {
      type: SELECT_INPUT,
      label: 'Return Warehouse',
      store: 'warehouseList',
      rules: 'required',
      firstSelect: true,
      options: {
        label: 'label',
      },
    },
    internal_dispatch_notes: {
      type: TEXTAREA_INPUT,
      label: 'Internal Shipping Notes (not visible to the customer)',
    },
  }
  // Dispatch & Return === // =>

  // Store Mutations
  const storeMutationsList = [
    {
      mutation: 'SET_ORDERS_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_CUSTOMER_INFORMATION_FORM',
      payload: {
        attachments: [],
      },
    },
    {
      mutation: 'SET_INSURANCE_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_DISPATCH_AND_RETURN_FORM',
      payload: {},
    },
    {
      mutation: 'SET_EVENT_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_ORDER_ITEMS_RENTAL_TABLE',
      payload: {
        products: [],
      },
    },
    {
      mutation: 'SET_ORDER_ITEMS_OTHER_CHARGES_TABLE',
      payload: {
        otherCharges: [],
      },
    },
    {
      mutation: 'SET_TOTALS_FORM',
      payload: {},
    },
    {
      mutation: 'SET_ON_ERROR',
      payload: {},
    },
    {
      mutation: 'SET_IS_SETTLED_CUSTOMER',
      payload: false,
    },
    {
      mutation: 'SET_IS_ORDER_INFORMATION_FORM_REQUIRED_FIELDS_FILLED',
      payload: false,
    },
    {
      mutation: 'SET_IS_EVENT_INFORMATION_REQUIRED_FIELDS_FILLED',
      payload: false,
    },
    {
      mutation: 'SET_IS_DISPATCH_AND_RETURN_ALL_REQUIRED_FIELDS_FILLED',
      payload: false,
    },
  ]

  // Form Buttons Title
  const FORM_BUTTON_PRINT = {
    title: 'Print',
    action: 'print',
  }
  const FORM_BUTTON_SAVE_AS_DRAFT = {
    title: 'Save as Draft',
    action: 'draft',
  }

  const FORM_BUTTON_EMAIL_SUPPLIER = {
    title: 'Email Supplier',
    action: 'send_email',
  }

  const FORM_BUTTON_CREATE_SERVICE_ORDER = {
    title: 'Create Service Order',
    action: 'create_service_order',
  }

  const FORM_BUTTON_QUOTE_RECEIVED = {
    title: 'Quote Received',
    action: 'quote_received',
  }

  const FORM_BUTTON_VOID = {
    title: 'VOID',
    action: 'void',
  }

  const formBottomActions = [
    {
      variant: 'danger',
      style: {
        minWidth: '125px',
        height: '40px',
        backgroundColor: '#6F1214 !important',
      },
      action: FORM_BUTTON_VOID.action,
      title: FORM_BUTTON_VOID.title,
      formSubmitAction: 'submit',
    },
    {
      variant: 'secondary',
      style: {
        minWidth: '180px',
        height: '40px',
      },
      action: FORM_BUTTON_SAVE_AS_DRAFT.action,
      title: FORM_BUTTON_SAVE_AS_DRAFT.title,
      formSubmitAction: 'submit',
    },
    {
      variant: 'secondary',
      style: {
        minWidth: '185px',
        height: '40px',
      },
      action: FORM_BUTTON_EMAIL_SUPPLIER.action,
      title: FORM_BUTTON_EMAIL_SUPPLIER.title,
      formSubmitAction: 'submit',
    },
    {
      variant: 'success',
      style: {
        minWidth: '195px',
        height: '40px',
      },
      action: FORM_BUTTON_QUOTE_RECEIVED.action,
      title: FORM_BUTTON_QUOTE_RECEIVED.title,
      formSubmitAction: 'submit',
    },
  ]

  const inHouseFormBottomActions = [
    {
      variant: 'secondary',
      style: {
        minWidth: '180px',
        height: '40px',
      },
      action: FORM_BUTTON_SAVE_AS_DRAFT.action,
      title: FORM_BUTTON_SAVE_AS_DRAFT.title,
      formSubmitAction: 'submit',
    },
    {
      variant: 'success',
      style: {
        minWidth: '230px',
        height: '40px',
      },
      action: FORM_BUTTON_CREATE_SERVICE_ORDER.action,
      title: FORM_BUTTON_CREATE_SERVICE_ORDER.title,
      formSubmitAction: 'submit',
    },
  ]

  return {
    store,
    model,
    MODULE_NAME,
    serviceType,
    tableColumns,
    FORM_BUTTON_VOID,
    FORM_BUTTON_PRINT,
    formBottomActions,
    MODULE_NAME_CLONE,
    storeMutationsList,
    MODULE_NAME_ORDERS,
    MODULE_NAME_ATTACHMENT,
    dispatchAndReturnFields,
    uploadAttachmentTableCol,
    inHouseFormBottomActions,
    FORM_BUTTON_SAVE_AS_DRAFT,
    FORM_BUTTON_EMAIL_SUPPLIER,
    FORM_BUTTON_QUOTE_RECEIVED,
    SERVICE_TYPE_SEND_OUT_SERVICE,
    SERVICE_TYPE_IN_HOUSE_SERVICE,
    serviceOrderInformationFields,
    autoSuggestTableColumnsAccount,
    DISPATCH_AND_RETURN_FORM_TITLE,
    DISPATCH_FORM_TITLE,
    RETURN_FORM_TITLE,
    SERVICE_ORDER_INFORMATION_TITLE,
    FORM_BUTTON_CREATE_SERVICE_ORDER,
    SERVICE_TYPE_IN_HOUSE_RESPONSE_ITEM_TYPE,
    SERVICE_TYPE_SEND_OUT_SERVICE_RESPONSE_ITEM_TYPE,
    MODULE_NAME_SET_ORDERS,
    MODULE_NAME_SET_ORDERS_CLONE,
  }
}
