import { TEXTAREA_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  // Form Buttons Title

  const FORM_BUTTON_VOID = {
    title: 'VOID',
    action: 'void',
  }

  const FORM_BUTTON_SELL_ASSET = {
    title: 'Sell Asset',
    action: 'sell_asset',
  }

  const FORM_BUTTON_BILL_SERVICE = {
    title: 'Bill Service',
    action: 'bill_service',
  }

  const FORM_BUTTON_PRINT = {
    title: 'Print',
    action: '',
  }

  const FORM_BUTTON_CLOSE_SERVICE_ORDER = {
    title: 'Close Service Order',
    action: 'close_service_order',
  }

  const formBottomActions = [
    {
      variant: 'danger',
      style: {
        minWidth: '125px',
        height: '40px',
      },
      action: FORM_BUTTON_VOID.action,
      title: FORM_BUTTON_VOID.title,
    },
    {
      variant: 'danger',
      style: {
        minWidth: '140px',
        height: '40px',
      },
      action: FORM_BUTTON_SELL_ASSET.action,
      title: FORM_BUTTON_SELL_ASSET.title,
    },
    {
      variant: 'secondary',
      style: {
        minWidth: '130px',
        height: '40px',
      },
      action: FORM_BUTTON_PRINT.action ?? '',
      title: FORM_BUTTON_PRINT.title,
      icon: 'LPrintIcon',
    },
    {
      variant: 'success',
      style: {
        minWidth: '215px',
        height: '40px',
      },
      action: FORM_BUTTON_CLOSE_SERVICE_ORDER.action,
      title: FORM_BUTTON_CLOSE_SERVICE_ORDER.title,
    },
  ]

  const serviceOrderInformationFields = {
    internal_order_reference_in_house: {
      type: TEXTAREA_INPUT,
      label: 'Internal Order Reference',
      placeholder: 'Write notes here...',
    },
  }

  const SERVICES_PERFORMED_TABLE_TITLE = 'Services Performed'
  const OTHER_CHARGES_ITEMS_TABLE_TITLE = 'Other Charges/Items'
  const RECEIVED_ATTACHMENTS = 'Received Attachments'

  return {
    FORM_BUTTON_VOID,
    FORM_BUTTON_PRINT,
    formBottomActions,
    RECEIVED_ATTACHMENTS,
    FORM_BUTTON_SELL_ASSET,
    FORM_BUTTON_BILL_SERVICE,
    serviceOrderInformationFields,
    SERVICES_PERFORMED_TABLE_TITLE,
    FORM_BUTTON_CLOSE_SERVICE_ORDER,
    OTHER_CHARGES_ITEMS_TABLE_TITLE,
  }
}
